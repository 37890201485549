<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center"></th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <SelectDeliveryCompany
                :label="$t('labels.delivery_company')"
                :placeholder="$t('labels.delivery_company')"
                name="id_delivery_company"
                sort-name="delivery_company_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <DateRangeFilter
                :label="$t('labels.create_time_1')"
                :placeholder="$t('labels.create_time_1')"
                name="create_time"
                sort-name="create_time"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!-- <th role="columnheader" class="text-center" style="min-width: 100px">
            <SelectPos :label="$t('labels.pos')" :placeholder="$t('labels.pos')" name="id_pos" sort-name="pos_code" :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th> -->
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <InputFilter
                :label="$t('labels.employee')"
                :placeholder="$t('labels.employee')"
                name="employee_name"
                sort-name="employee_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <SelectFilter
                :options="imageOptions"
                label="Ảnh biên bản"
                placeholder="Ảnh biên bản"
                name="has_image"
                sort-name="has_image"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <InputFilterFromTo
                :label="$t('labels.order')"
                :placeholder="$t('labels.order')"
                name="count_order"
                sort-name="count_order"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <InputFilter
                :label="$t('labels.tracking')"
                :placeholder="$t('labels.tracking')"
                name="order_tracking_id"
                @onFilter="onFilterChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <td>
              <UploadHandoverFile
                v-if="checkRoleUpDownload(item)"
                :item="item"
                @refreshData="getList"
              />
            </td>
            <td>{{ item.delivery_company_name }}</td>
            <td>{{ formatDateTime(item.time) }}</td>
            <!-- <td>{{ item.pos_code }}</td> -->
            <td>{{ item.employee_name }}</td>
            <td><HandoverImage :item="item" /></td>
            <td>{{ item.count_order }}</td>
            <td>
              <v-btn
                v-if="checkRoleUpDownload(item)"
                small
                color="success"
                outlined
                @click="downloadHandoverFile(item)"
              >
                <img
                  src="@/assets/common/download.png"
                  style="height: 24px"
                  alt=""
                />
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="pt-3">
      <v-col cols="12" class="text-right">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { IMAGE_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  components: {
    // SelectPos: () => import('@/components/table/SelectPos'),
    SelectDeliveryCompany: () =>
      import("@/components/table/SelectDeliveryCompany"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    InputFilter: () => import("@/components/table/InputFilter"),
    UploadHandoverFile: () =>
      import("@/components/order_online/HandoverUploadFile"),
    HandoverImage: () => import("@/components/order_online/HandoverImage"),
  },
  name: "HandoverHistory",
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    filters: {},
    sort: {},
    isLoading: false,
    imageOptions: [...IMAGE_OPTIONS],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    checkRoleUpDownload(item) {
      if (!item.file && item.id_orders) {
        return false;
      }
      if (item.count_order < 1) {
        return false;
      }
      if (!window.me || !window.me.employee || !window.me.employee.id) {
        return false;
      }
      if (this.checkPermission(["super_admin"])) {
        return true;
      }
      const employeeId = window.me.employee.id;
      return employeeId === item.id_employee;
    },
    getList: debounce(function () {
      httpClient
        .post("/order-online-handover-list", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 1000),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    downloadHandoverFile(item) {
      this.downloadExcelFile(
        "/order-online-handover-file",
        { id_handover: item.id },
        "A4_Bien_ban_ban_giao.xlsx"
      );
      // this.downloadPdfFile(`${process.env.VUE_APP_WEB_V1_URL}/print/export-hand-over-delivery-record?id_record=${item.id}`)
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
